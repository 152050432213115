import './SecretNav.scss'
import Hamburger from '../Hamburger/Hamburger';

function SecretNav({ showModal, setShowModal }) {
    return (  
        <div className="home-page__secret-nav">
                <nav className="secret-nav">
                    <a href="#top" className="secret-nav__title">Gabe</a>
                    <Hamburger showModal={showModal} setShowModal={setShowModal} className="secret-nav__ham"/>
                </nav>
        </div>
    );
}

export default SecretNav;