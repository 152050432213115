import gabeSitting from "../../assets/images/gabe-sitting.png"
import Hamburger from "../../components/Hamburger/Hamburger"
import "./HomePage.scss"

function HomePage({ showModal, setShowModal }) {
    return (
        <>
            <main className="home-page">
                <Hamburger showModal={showModal} setShowModal={setShowModal}/>
                <div className="home-page__hero hero">
                    <div className="hero__left-section">
                        <h1 className="hero__main-intro">Salutations, <br></br> My name is Gabe</h1>
                        <p className="hero__sub-intro">I AM A FULL TIME SOFTWARE ENGINEER</p>
                    </div>
                    <div className="hero__right-section">
                        <div className="hero__gabe-seat">
                            <img className="hero__gabe-sitting" src={gabeSitting} alt="Me sitting down with a thumbs up" />
                        </div>
                    </div>
                </div>
                <div className="home-page__gradient"></div>
            </main>
        </>  
    );
}

export default HomePage;