import "./AboutMe.scss"
import gabeResume from "../../assets/gabrielcruz-resume.pdf"

function AboutMe({ showModal }) {
    if(showModal) {
        return (
            <section id="about" className="about-me about-me--burger">
                <h2 className="about-me__heading">ABOUT ME</h2>
                <h3 className="about-me__sub-heading">A BRIEF SUMMARY</h3>
                <p className="about-me__copy about-me--burger">
                    Hi! My name is Gabe and I’m glad you decided to take a look at my portfolio. Growing up in a musical family I’ve always been in an environment where creativity and growth were encouraged, so I’ve looked to find a career that has those same qualities. However, after working jobs at Dairy Queen and as a mover, it proved difficult. But, after seeing my brother complete a coding bootcamp and break into the industry, I saw that I could do the same. <br></br>
                
                    <br></br>After graduating from the Web Development program at BrainStation I believe I have the potential and drive to succeed in this industry. It complements my constant desire to learn and grow, and due to my background in Communications, I believe I would work well in this team-oriented field. I am currently pursuing a Bachelor’s in Organizational Communications at FIU while working as a Freelance Web Developer, however, I am looking for a more secure position where I can grow in.
                </p>
                <button className="about-me__resume-btn">LOAD MY RESUME</button>
            </section>
        )
    } else {
        return (  
            <section id="about" className="about-me">
                <h2 className="about-me__heading">ABOUT ME</h2>
                <h3 className="about-me__sub-heading">A BRIEF SUMMARY</h3>
                <p className="about-me__copy">
                    Hi! My name is Gabe and I’m glad you decided to take a look at my portfolio. Growing up in a musical family I’ve always been in an environment where creativity and growth were encouraged, so I’ve looked to find a career that has those same qualities. However, after working jobs at Dairy Queen and as a mover, it proved difficult. But, after seeing my brother complete a coding bootcamp and break into the industry, I saw that I could do the same. <br></br>
                 
                    <br></br>After graduating from the Web Development program at BrainStation I believe I have the potential and drive to succeed in this industry. It complements my constant desire to learn and grow, and due to my background in Communications, I believe I would work well in this team-oriented field. I am currently pursuing a Bachelor’s in Organizational Communications at FIU while working as a Freelance Web Developer, however, I am looking for a more secure position where I can grow in.
                </p>
                <a href={gabeResume} className="about-me__resume-btn" download="gabrielcruz-resume">LOAD MY RESUME</a>
            </section>
        );
    }
}

export default AboutMe;