import ContactForm from "../../components/ContactForm/ContactForm";
import mailIcon from "../../assets/icons/mail.svg"
import phoneIcon from "../../assets/icons/phone.svg"
import gabeLying from "../../assets/images/gabe-lying.png"
import SocialMedia from "../../components/SocialMedia/SocialMedia";
import "./Contact.scss"

function Contact() {
    return (
        <div className="contact__section" id="contact">
            <section className="contact">
                <div className="contact__gradient-top"></div>
                <img className="contact__gabe-lying" src={gabeLying} alt="gabe lying down, giving a thumbs up"/>
                <div className="contact__left">
                    <h2 className="contact__heading">Get in touch with me!</h2>
                    <p className="contact__copy">Whether you want to chat about the latest in web development, or you have any business inquiries, my contact info is below!</p>
                    <div className="contact__comm-buttons">
                        <a href="mailto:gabriel@gacweb.dev" className="contact__comm-button comm-button">
                            <div className="comm-button__bg">
                                <img src={mailIcon} alt="" className="comm-button__icon" />
                            </div>
                            <p className="comm-button__info">gabriel@gacweb.dev</p>
                        </a>
                        <div className="contact__comm-button comm-button">
                            <div className="comm-button__bg">
                                <img src={phoneIcon} alt="" className="comm-button__icon" />
                            </div>
                            <p className="comm-button__info">954-591-1526</p>
                        </div>
                    </div>
                    <div className="contact__left--gradient"></div>
                </div>
                <div className="contact__right">
                    <ContactForm/>
                </div>
                <div className="contact__gradient-bottom"></div>
            </section>
            <SocialMedia/>
        </div>
    );
}

export default Contact;