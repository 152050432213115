import { useEffect } from "react";
import HamburgerIcon from "../../assets/icons/hamburger.svg"
import xExit from "../../assets/icons/exit-ham.svg"
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import gabeResume from "../../assets/gabrielcruz-resume.pdf"
import { useMediaQuery } from "react-responsive"
import "./Hamburger.scss"

function Hamburger({ showModal, setShowModal }) {

    const isDesktop = useMediaQuery({ query: '(min-width: 1049px)'});

    const handleClick = () => {
        setShowModal(!showModal)
    }

    
    useEffect(() => {
        if(!isDesktop) {
            showModal ? disableBodyScroll(document) : enableBodyScroll(document)
        }
    }, [showModal, setShowModal, isDesktop])

    if(!showModal){
        return (
            <img className="ham__icon" onClick={handleClick} src={HamburgerIcon} alt="3 lines stacked, hamburger style" />
        )
    } 

    if(showModal) {
        return (
            <div className="ham">
                <img className="ham__icon ham__icon--exit" onClick={handleClick} src={xExit} alt="3 lines stacked, hamburger style" />
                <ul className="ham__list">
                    <li className="ham__item"><a href={gabeResume} onClick={handleClick} className="ham__link" download="gabrielcruz-resume">Resume</a></li>
                    <li className="ham__item"><a href="#about" onClick={handleClick} className="ham__link">About Me</a></li>
                    <li className="ham__item"><a href="#portfolio" onClick={handleClick} className="ham__link">Portfolio</a></li>
                    <li className="ham__item"><a href="#contact" onClick={handleClick} className="ham__link">Contact</a></li>
                </ul>
            </div>
        )
    }
}

export default Hamburger;