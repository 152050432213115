import { useState } from "react";
import Project from "../../components/Project/Project";
import "./MyProjects.scss"
import {v4 as uuid} from 'uuid'
import buildAListPhone from "../../assets/images/build-a-list-phone.png"
import macriskPhone from "../../assets/images/macrisk-phone.png"
import miguelKofcPhone from "../../assets/images/miguel-kofc-phone.png"


function MyProjects() {
    const [selected, setSelected] = useState("All")

    const projects = 
    [
        {
            title: "MIGUEL CRUZ'S KOFC WEBSITE",
            link: "https://www.miguelcruzkofc.com",
            type: ["All", "Web"],
            img: miguelKofcPhone
        },
        {
            title: "BUILD-A-LIST",
            link: "https://github.com/gabe305/build-a-list-client",
            type: ["All"],
            img: buildAListPhone
        },
        {
            title: "MAC RISK SERVICES",
            link: "https://www.macriskservices.com",
            type: ["All", "Web"],
            img: macriskPhone
        },
        {
            title: "CANADA GOOSE INDUSTRY PROJECT",
            link: "https://github.com/gabe305/industry-project-goose",
            type: ["All"],
        }
    ]
        
    return (

            <section className="projects" id="portfolio">
                <div className="projects__gradient-top"></div>
                <h2 className="projects__heading">MY PROJECTS</h2>
                <h3 className="projects__sub-heading">Check out my GitHub to see more projects!</h3>
                <div className="projects__categories">
                    <button className={`projects__select-cat + ${selected === 'All' ? 'projects__select-cat--active' : null}`} onClick={() => {setSelected("All")}}>ALL</button>
                    <button className={`projects__select-cat + ${selected === 'Web' ? 'projects__select-cat--active' : null}`} onClick={() => {setSelected("Web")}}>WEB</button>
                    <button className={`projects__select-cat + ${selected === 'Apps' ? 'projects__select-cat--active' : null}`}>APPS</button>
                    <button className={`projects__select-cat + ${selected === 'Coursework' ? 'projects__select-cat--active' : null}`}>COURSEWORK</button>
                </div>
                <div className="projects__laptops">
                    <div className="projects__gradient-laptops"></div>
                    {projects.map(project => (
                        project.type.includes(selected) ? <Project key={uuid()} title={project.title} img={project.img} link={project.link ? project.link : ""}/> : selected ? null : <Project key={uuid()} link={project.link ? project.link : ""} title={project.title}/>
                    ))}
                </div>
                {/* <button className="projects__load-btn">LOAD MORE PROJECTS</button> */}
            </section>
    );
}

export default MyProjects;