import "./WhatIOffer.scss"
import languagesImg from "../../assets/icons/languages.svg"
import stylingImg from "../../assets/icons/styling.svg"
import databasesImg from "../../assets/icons/databases.svg"
import toolsImg from "../../assets/icons/tools.svg"

function WhatIOffer() {
    return (  
        <section className="offer">
            <div className="offer__gradient-top"></div>
            <h2 className="offer__heading">WHAT I OFFER</h2>
            <h3 className="offer__sub-heading">PREVALENT SKILLS</h3>
            <div className="offer__cards">
                <div className="offer__gradient-cards"></div>
                <div className="offer__card card">
                    <div className="card__img-bg">
                        <img src={stylingImg} alt="a pen making drawing motions" className="card__img" />
                    </div>
                    <h4 className="card__heading">FRONT-END/STYLING</h4>
                    <p className="card__copy">HTML, CSS, SASS, React, Bootstrap, Gatsby, Ect.</p>
                </div>
                <div className="offer__card card">
                    <div className="card__img-bg">
                        <img src={languagesImg} alt="desktop and mobile phone" className="card__img" />
                    </div>
                    <h4 className="card__heading">LANGUAGES</h4>
                    <p className="card__copy">JavaScript</p>
                </div>
                <div className="offer__card card">
                    <div className="card__img-bg">
                        <img src={databasesImg} alt="a robot" className="card__img" />
                    </div>
                    <h4 className="card__heading">BACK-END/DATABASES</h4>
                    <p className="card__copy">Node.js, Express, MySQL, REST APIs, MongoDB, Firebase</p>
                </div>
                <div className="offer__card card">
                    <div className="card__img-bg">
                        <img src={toolsImg} alt="a magic wand with sparkles around it" className="card__img" />
                    </div>
                    <h4 className="card__heading">TOOLS</h4>
                    <p className="card__copy">Figma, Git, Heroku, Linux, Postman, Netlify</p>
                </div>
            </div>
        </section>
    );
}

export default WhatIOffer;