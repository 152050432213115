import HomePage from './pages/HomePage/HomePage'
import AboutMe from './pages/AboutMe/AboutMe';
import WhatIOffer from './pages/WhatIOffer/WhatIOffer';
import MyProjects from './pages/MyProjects/MyProjects';
import Contact from './pages/Contact/Contact';
import { useState } from 'react';
import "./App.scss"
import SecretNav from './components/SecretNav/SecretNav';

function App() {
  const [showModal, setShowModal] = useState(false)

  return (
    <div className="App">
      <HomePage showModal={showModal} setShowModal={setShowModal}/>
      <SecretNav showModal={showModal} setShowModal={setShowModal}/>
      <AboutMe showModal={showModal}/>
      <WhatIOffer/>
      <MyProjects/>
      <Contact/>
    </div>
  );
}

export default App;
