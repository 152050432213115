import blank from "../../assets/images/coming-soon-phone.png"

function Project({ title, link, img }) {
    return (  
        <a href={link ? link : null} className="projects__project">
            <img src={img || blank} alt="" className="projects__img" />
            <p className="projects__title">{title}</p>
        </a>
    );
}

export default Project;