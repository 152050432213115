import "./SocialMedia.scss"
import github from "../../assets/icons/github.svg"
import instagram from "../../assets/icons/instagram.svg"
import linkedIn from "../../assets/icons/linkedIn.svg"

function SocialMedia() {
    return (  
        <section className="socials">
            <h2 className="socials__heading">Social Media</h2>
            <ul className="socials__icons">
                <li className="socials__item">
                    <a href="https://www.linkedin.com/in/gabriel-angel-cruz/" className="socials__link">
                        <img className="socials__icon" src={linkedIn} alt="" />
                        <p className="socials__text socials__text--blue">LinkedIn</p>
                    </a>
                </li>
                <li className="socials__item">
                    <a href="https://github.com/gabe305" className="socials__link">
                        <img className="socials__icon" src={github} alt="" />
                        <p className="socials__text">GitHub</p>
                    </a>
                </li>
                <li className="socials__item">
                    <a href="https://www.instagram.com" className="socials__link">
                        <img className="socials__icon" src={instagram} alt="" />
                        <p className="socials__text">Instagram</p>
                    </a>
                </li>
            </ul>
        </section>
    );
}

export default SocialMedia;